import { useField } from 'formik';
import {
    CCol,
    CFormLabel,
    CRow,
    CFormSelect,
  } from '@coreui/react'
  
export function formatDate(date, separator, timeRequired) {
    if (separator == null)
        separator = "/";

    let d = Date.parse(date) != NaN ? date : null;

    if (d != null) {
        let dateObj = new Date(d);
        let dateFormatted = dateObj.getDate().toString().padStart(2, '0') + separator + (dateObj.getMonth() + 1).toString().padStart(2, '0') + separator + dateObj.getFullYear();

        if (timeRequired == true)
            dateFormatted += " " + dateObj.getHours().toString().padStart(2, '0') + ":" + dateObj.getMinutes().toString().padStart(2, '0');

        return dateFormatted;
    }
    else {
        return '';
    }
}

export function getCookie (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const MyTextInput = ({ label, viewOnly, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);

    if(viewOnly)
    {
        return (
            <CRow className="mb-1 gray-bg">
                <CCol xs={6}>
                    <CFormLabel htmlFor={props.id || props.name}>{label}</CFormLabel>
                </CCol>
                <CCol xs={6} className="text-right">
                    <span className="custom_label">{field.value}</span>
                </CCol>
            </CRow>
        );
    }
    else
        return (
            <CRow className="mb-3">
                <CCol xs={6} className="gray-bg">
                    <CFormLabel htmlFor={props.id || props.name}>{label}</CFormLabel>
                </CCol>
                <CCol xs={6} className="text-right">
                    <input type="text" className="form-control form-control-sm" {...field} {...props} />
                    {meta.touched && meta.error ? (<div style={{fontSize:'0.875rem'}} className="text-danger">{meta.error}</div>) : null}
                </CCol>
            </CRow>
        );
};

export const MyTextAreaInput = ({ label, viewOnly, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);

    if(viewOnly)
    {
        return (
            <CRow className="mb-1 gray-bg">
                <CCol xs={6}>
                    <CFormLabel htmlFor={props.id || props.name}>{label}</CFormLabel>
                </CCol>
                <CCol xs={6} className="text-right">
                    <span className="custom_label">{field.value}</span>
                </CCol>
            </CRow>
        );
    }
    else
        return (
            <CRow className="mb-3">
                <CCol xs={6} className="gray-bg">
                    <CFormLabel htmlFor={props.id || props.name}>{label}</CFormLabel>
                </CCol>
                <CCol xs={6} className="text-right">
                    <textarea className="form-control form-control-sm" {...field} {...props} />
                    {meta.touched && meta.error ? (<div style={{fontSize:'0.875rem'}} className="text-danger">{meta.error}</div>) : null}
                </CCol>
            </CRow>
        );
};

export const MySelect = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    
    return (
        <CRow className="mb-3">
            <CCol xs={6} className="gray-bg">
                <CFormLabel htmlFor={props.id || props.name}>{label}</CFormLabel>
            </CCol>
            <CCol xs={6} className="text-right">
                <CFormSelect size="sm" {...field} {...props} />
                {meta.touched && meta.error ? (<div style={{fontSize:'0.875rem'}} className="text-danger">{meta.error}</div>) : null}
            </CCol>
        </CRow>
    );
};

export const MyCheckbox = ({ children, ...props }) => {
// React treats radios and checkbox inputs differently other input types, select, and textarea.
// Formik does this too! When you specify `type` to useField(), it will
// return the correct bag of props for you -- a `checked` prop will be included
// in `field` alongside `name`, `value`, `onChange`, and `onBlur`
const [field, meta] = useField({ ...props, type: 'checkbox' });
return (
    <div>
    <label className="checkbox-input">
        <input type="checkbox" {...field} {...props} />
        {children}
    </label>
    {meta.touched && meta.error ? (
        <div style={{fontSize:'0.875rem'}} className="text-danger">{meta.error}</div>
    ) : null}
    </div>
);
};